.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  top: 0;

  @media (min-width: 992px) {
    top: 0;
  }
}
.navbar.sticky {
  background-color: #0b0703 !important; // #343b40 !important;
  padding: 0 !important;

  .navbar.collapsed {
    span {
      background-color: white !important;

      &:nth-child(1) {
        transform: rotate(135deg);
        margin-top: 7px;
      }

      &:nth-child(2) {
        opacity: 0;
        left: -23px * 3;
      }

      &:nth-child(3) {
        transform: rotate(-135deg);
        margin-top: 7px;
      }
    }
  }
}
.navbar {
  background-color: transparent;
  transition: all 0.5s ease-in-out;

  > .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  .navbar-brand img {
    width: 300px;
    height: auto;

    @media (max-width: 576px) {
      width: 200px;
      height: auto;
    }
  }

  .navbar-collapse {
    height: auto !important;
    @media (max-width: 991px) {
      background: $color-black;
      padding: 20px;
      padding-top: 83px;
      position: fixed;
      top: 0;
      right: 0;
      height: auto;
      width: 0%;
      z-index: 1;
      opacity: 0;
      transition: all 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
      transition-delay: 100ms;

      &.show {
        width: 250px;
        opacity: 1;
      }
      a {
        color: white !important;
      }
    }

    @media (max-width: 576px) {
    }
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: 992px) {
      flex-direction: row;
    }

    a {
      font-size: 12px !important;
    }
    .nav-link {
      margin: 0 10px;
    }
  }

  .navbar-toggler {
    height: 16px;
    width: 23px;
    cursor: pointer;
    padding: 0;
    border: 0;
    background: transparent;
    outline: 0;
    box-shadow: none;
    z-index: 9;
    position: relative;

    > span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #fff none repeat scroll 0% 0%;
      opacity: 1;
      top: 0;
      left: 0px;
      transition: all 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
      transition-delay: 100ms;
      transform: rotate(0deg);

      &:nth-child(1) {
        margin-top: 0px;
      }

      &:nth-child(2) {
        margin-top: 7px;
        margin-right: 0px;
      }

      &:nth-child(3) {
        margin-top: 14px;
      }
    }

    &.collapsed {
      span {
        background-color: black !important;

        &:nth-child(1) {
          transform: rotate(135deg);
          margin-top: 7px;
        }

        &:nth-child(2) {
          opacity: 0;
          left: -23px * 3;
        }

        &:nth-child(3) {
          transform: rotate(-135deg);
          margin-top: 7px;
        }
      }
    }

    @media (min-width: 992px) {
      display: none;
    }
  }

  .nav-link {
    @include font-size(14);
    margin: 0;
    font-weight: bold;
    line-height: normal;
    color: #fff;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -1px;
      left: 0;
      background-color: $color-red;
      visibility: hidden;
      transform: scaleX(0);
      transition: all 0.3s ease-in-out 0s;
    }

    &:hover,
    &.active {
      &:after {
        visibility: visible;
        transform: scaleX(1);
      }
    }

    @media (max-width: 991px) {
      &:hover,
      &.active {
        color: #fff;
      }

      padding: 15px 0;
      border-bottom: 1px solid #19212d;
      @include font-size(18);
      font-weight: 300;
    }

    @media (min-width: 992px) {
      margin: 0 25px;

      &:after {
        height: 3px;
        bottom: -3px;
      }
    }
  }

  &.sticky {
    top: 0;
    background-color: #fff;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.14);
    padding: 15px 0;

    .navbar-collapse {
      @media (max-width: 991px) {
        padding-top: 63px;
      }
    }

    .navbar-toggler {
      > span {
        background-color: white !important;
      }
    }
  }

  @media (min-width: 992px) {
    flex-wrap: nowrap;
    padding: 0;

    &.sticky {
      padding: 19px 0px 18px 0;
      top: 0;

      .nav-link {
        color: $color-gray;

        &:hover,
        &.active {
          color: $color-red;
        }
      }
    }
  }
}
