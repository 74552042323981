@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&subset=latin-ext");

html {
  font-size: $baseFontSize;
}

body {
  font-family: "Helvetica Neue", sans-serif;
}

a,
a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
    min-width: 100%;
  }
}
