@import "abstracts/variables";
@import "abstracts/functions";
@import "abstracts/mixins";
@import "base/typography";
@import "pages/header";

section {
  + section {
    background-color: #fff;
    min-height: 100vh;
    color: $color-lightgray;
    background-color: $color-white;
    color: $color-black;
    text-align: center;
    color: $color-black;
    display: flex;
    align-items: center;
    justify-content: center;
    + section {
      background-color: #fff;
      min-height: 100vh;
      color: $color-lightgray;
      background-color: $color-white;
      color: $color-black;
      text-align: center;
      color: $color-black;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.dll {
  width: 100%;
}

.input-check-fallback {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.navbar .navbar-nav a {
  font-size: 14px;
  text-transform: uppercase;
}

.form-control-fallback {
  width: inherit !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #8f8f8f !important;
}

.navbar.sticky .nav-link.active {
  color: #fa3840 !important;
}
