@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&subset=latin-ext);
html {
  font-size: 14; }

body {
  font-family: "Helvetica Neue", sans-serif; }

a,
a:hover {
  text-decoration: none; }

@media (max-width: 991px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
    min-width: 100%; } }

.fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
  top: 0; }
  @media (min-width: 992px) {
    .fixed-top {
      top: 0; } }

.navbar.sticky {
  background-color: #0b0703 !important;
  padding: 0 !important; }
  .navbar.sticky .navbar.collapsed span {
    background-color: white !important; }
    .navbar.sticky .navbar.collapsed span:nth-child(1) {
      -webkit-transform: rotate(135deg);
              transform: rotate(135deg);
      margin-top: 7px; }
    .navbar.sticky .navbar.collapsed span:nth-child(2) {
      opacity: 0;
      left: -69px; }
    .navbar.sticky .navbar.collapsed span:nth-child(3) {
      -webkit-transform: rotate(-135deg);
              transform: rotate(-135deg);
      margin-top: 7px; }

.navbar {
  background-color: transparent;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }
  .navbar > .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }
  .navbar .navbar-brand img {
    width: 300px;
    height: auto; }
    @media (max-width: 576px) {
      .navbar .navbar-brand img {
        width: 200px;
        height: auto; } }
  .navbar .navbar-collapse {
    height: auto !important; }
    @media (max-width: 991px) {
      .navbar .navbar-collapse {
        background: #121821;
        padding: 20px;
        padding-top: 83px;
        position: fixed;
        top: 0;
        right: 0;
        height: auto;
        width: 0%;
        z-index: 1;
        opacity: 0;
        -webkit-transition: all 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
        transition: all 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
        -webkit-transition-delay: 100ms;
                transition-delay: 100ms; }
        .navbar .navbar-collapse.show {
          width: 250px;
          opacity: 1; }
        .navbar .navbar-collapse a {
          color: white !important; } }
  .navbar .navbar-nav {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (min-width: 992px) {
      .navbar .navbar-nav {
        flex-direction: row; } }
    .navbar .navbar-nav a {
      font-size: 12px !important; }
    .navbar .navbar-nav .nav-link {
      margin: 0 10px; }
  .navbar .navbar-toggler {
    height: 16px;
    width: 23px;
    cursor: pointer;
    padding: 0;
    border: 0;
    background: transparent;
    outline: 0;
    box-shadow: none;
    z-index: 9;
    position: relative; }
    .navbar .navbar-toggler > span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #fff none repeat scroll 0% 0%;
      opacity: 1;
      top: 0;
      left: 0px;
      -webkit-transition: all 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
      transition: all 600ms cubic-bezier(0.62, 0.04, 0.3, 1.56);
      -webkit-transition-delay: 100ms;
              transition-delay: 100ms;
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg); }
      .navbar .navbar-toggler > span:nth-child(1) {
        margin-top: 0px; }
      .navbar .navbar-toggler > span:nth-child(2) {
        margin-top: 7px;
        margin-right: 0px; }
      .navbar .navbar-toggler > span:nth-child(3) {
        margin-top: 14px; }
    .navbar .navbar-toggler.collapsed span {
      background-color: black !important; }
      .navbar .navbar-toggler.collapsed span:nth-child(1) {
        -webkit-transform: rotate(135deg);
                transform: rotate(135deg);
        margin-top: 7px; }
      .navbar .navbar-toggler.collapsed span:nth-child(2) {
        opacity: 0;
        left: -69px; }
      .navbar .navbar-toggler.collapsed span:nth-child(3) {
        -webkit-transform: rotate(-135deg);
                transform: rotate(-135deg);
        margin-top: 7px; }
    @media (min-width: 992px) {
      .navbar .navbar-toggler {
        display: none; } }
  .navbar .nav-link {
    font-size: 14px;
    font-size: 1rem;
    margin: 0;
    font-weight: bold;
    line-height: normal;
    color: #fff;
    position: relative; }
    .navbar .nav-link:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      bottom: -1px;
      left: 0;
      background-color: #fa3840;
      visibility: hidden;
      -webkit-transform: scaleX(0);
              transform: scaleX(0);
      -webkit-transition: all 0.3s ease-in-out 0s;
      transition: all 0.3s ease-in-out 0s; }
    .navbar .nav-link:hover:after, .navbar .nav-link.active:after {
      visibility: visible;
      -webkit-transform: scaleX(1);
              transform: scaleX(1); }
    @media (max-width: 991px) {
      .navbar .nav-link {
        padding: 15px 0;
        border-bottom: 1px solid #19212d;
        font-size: 18px;
        font-size: 1.28571rem;
        font-weight: 300; }
        .navbar .nav-link:hover, .navbar .nav-link.active {
          color: #fff; } }
    @media (min-width: 992px) {
      .navbar .nav-link {
        margin: 0 25px; }
        .navbar .nav-link:after {
          height: 3px;
          bottom: -3px; } }
  .navbar.sticky {
    top: 0;
    background-color: #fff;
    box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.14);
    padding: 15px 0; }
    @media (max-width: 991px) {
      .navbar.sticky .navbar-collapse {
        padding-top: 63px; } }
    .navbar.sticky .navbar-toggler > span {
      background-color: white !important; }
  @media (min-width: 992px) {
    .navbar {
      flex-wrap: nowrap;
      padding: 0; }
      .navbar.sticky {
        padding: 19px 0px 18px 0;
        top: 0; }
        .navbar.sticky .nav-link {
          color: #8f8f8f; }
          .navbar.sticky .nav-link:hover, .navbar.sticky .nav-link.active {
            color: #fa3840; } }

section + section {
  background-color: #fff;
  min-height: 100vh;
  color: #f8faff;
  background-color: #ffffff;
  color: #121821;
  text-align: center;
  color: #121821;
  display: flex;
  align-items: center;
  justify-content: center; }
  section + section + section {
    background-color: #fff;
    min-height: 100vh;
    color: #f8faff;
    background-color: #ffffff;
    color: #121821;
    text-align: center;
    color: #121821;
    display: flex;
    align-items: center;
    justify-content: center; }

.dll {
  width: 100%; }

.input-check-fallback {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }

.navbar .navbar-nav a {
  font-size: 14px;
  text-transform: uppercase; }

.form-control-fallback {
  width: inherit !important; }

.navbar-light .navbar-nav .nav-link {
  color: #8f8f8f !important; }

.navbar.sticky .nav-link.active {
  color: #fa3840 !important; }

